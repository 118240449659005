const authActionType = {
  GET_USER_PENDING: "GET_USER_PENDING",
  GET_USER_FULFILLED: "GET_USER_FULFILLED",
  GET_USER_REJECTED: "GET_USER_REJECTED",
  LOGIN_PENDING: "LOGIN_PENDING",
  LOGIN_FULFILLED: "LOGIN_FULFILLED",
  LOGIN_REJECTED: "LOGIN_REJECTED",
  LOGOUT_PENDING: "LOGOUT_PENDING",
  LOGOUT_FULFILLED: "LOGOUT_FULFILLED",
  LOGOUT_REJECTED: "LOGOUT_REJECTED",
  REGISTER_PENDING: "REGISTER_PENDING",
  REGISTER_FULFILLED: "REGISTER_FULFILLED",
  REGISTER_REJECTED: "REGISTER_REJECTED",
  CLEAR_AUTH_ERROR: "CLEAR_AUTH_ERROR",
};

export default Object.freeze(authActionType);
